import $ from 'jquery-slim';


class OptScrollbar
{
    constructor()
    {
        $(document).ready(this.init.bind(this));
    }

    init()
    {
        let $elements = $('[opt-scrollbar]');
        let $element;
        let attr;
        let conf;

        let d =
            {
                overflowBehavior :
                    {
                        x: 'hidden',
                        y: 'scroll'
                    }
            }

        for (let i = 0; i < $elements.length; i++)
        {
            $element = $elements.eq(i);
            //attr = $element.attr('opt-scrollbar');
            //conf = attr ? JSON.parse(attr) :  {};
            $element.overlayScrollbars(d).overlayScrollbars();
        }
    }

}

const scrollbar = new OptScrollbar();






